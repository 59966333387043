.select-custom .dropdown-menu {

   
    left: -110% !important;
    min-width: initial;
}

.select-custom .dropdown-menu button {

   
    border-bottom:  black solid 2px;
}
.select-custom .dropdown-menu .ultimo {

   
    border-bottom:  black solid 0px;
}